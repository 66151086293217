<h1 class="header-title">Aktuelle Nachrichten</h1>
<nz-list class="news-list" [infiniteScrollContainer]="'.layout-wrapper'" [fromRoot]="true"
         infiniteScroll [infiniteScrollDistance]="2" (scrolled)="onScroll($event)">
  <div *ngFor="let feed of rssNews" class="news-card">
    <nz-card [nzBordered]="false" [nzBodyStyle]="{'height': '100%'}">
      <ng-container *ngTemplateOutlet="feed.source == 'youcar' ? video : news"></ng-container>
      <ng-template #news>
        <div class="news-container">
          <div class="col-1">
            <div class="news-header">
              <div class="logo-container">
                <img referrerpolicy="no-referrer" src="{{sourceMap[feed.source]['src']}}" alt="" class="brandLogo">
                <span nz-typography class="news-logo-text"><strong>{{ sourceMap[feed.source]['name'] }}</strong></span>
              </div>
              <span class="published-date">{{ formatDate(feed.pubDate) }}</span>
            </div>
            <a href="{{feed.link}}" target="_blank">
              <div class="image-container">
                <img
                  referrerpolicy="no-referrer"
                  src="{{feed.img}}"
                  class="image"
                  onerror="this.style.display = 'none'"
                />
              </div>
            </a>
          </div>
          <div class="col-2">
            <a href="{{feed.link}}" target="_blank">
              <h3 nz-typography class="news-content-header">{{ feed.title }}</h3>
            </a>
            <div class="news-content">
              <a href="{{feed.link}}" target="_blank">
                <div [ngClass]="feed.source">{{ feed.description }}</div>
              </a>
              <a href="{{feed.link}}" target="_blank">
                <div class="read-more">
                  Den ganzen Artikel lesen
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #video>
        <div class="news-container">
          <div class="col-1">
            <div class="news-header">
              <div class="logo-container">
                <img referrerpolicy="no-referrer" src="{{sourceMap[feed.source]['src']}}" alt="" class="brandLogo">
                <span nz-typography class="news-logo-text"><strong>{{ sourceMap[feed.source]['name'] }}</strong></span>
              </div>
              <span class="published-date">{{ formatDate(feed.pubDate) }}</span>
            </div>
            <div class="image-container">
              <youtube-player
                [videoId]="getId(feed)"
                suggestedQuality="small"
                [width]="400"
                [height]="225"
              >
              </youtube-player>
            </div>
          </div>
          <div class="col-2">
            <a href="{{feed.link}}" target="_blank">
              <h3 nz-typography class="news-content-header">{{ feed.title }}</h3>
            </a>
            <div class="news-content">
              <a href="{{feed.link}}" target="_blank">
                <div [ngClass]="feed.source">{{ feed.description }}</div>
              </a>
              <a href="{{feed.link}}" target="_blank">
                <div class="read-more">
                  Video ansehen
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </nz-card>
  </div>
  <div class="loadmore" nz-list-load-more>
    <ng-template #indicatorTemplate><span style="height: 32px;" nz-icon nzType="loading"></span></ng-template>
    <nz-spin *ngIf="loadingMore" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
    <button nz-button nzSize="large" nzType="primary" *ngIf="!loadingMore && hasMoreNews && isLoadMoreBtnVisible"
            (click)="onLoadMore()">
      Mehr laden
    </button>
  </div>
</nz-list>
