<div class="page">
  <div class="page-content">
<div class="container">
  <div class="acc">
    <div class="divider">
      <button
        nz-button
        nzType="primary"
        nzDanger
        (click)="addImporterAccount(tplContent, tplFooter)"
      >
        <span nz-icon nzType="plus"></span>
        Importer hinzufügen
      </button>
    </div>
    <nz-skeleton [nzActive]="true" *ngIf="accLoading"></nz-skeleton>
    <div class="accounts" *ngIf="!accLoading">
      <nz-empty
        *ngIf="accounts.length == 0"
        nzNotFoundImage="simple"
      ></nz-empty>
      <div *ngFor="let importerAccount of accounts">
        <nz-card
          [nzTitle]="importerAccount.supplier_system_partner_name"
          [nzExtra]="verificationTemplate"
        >
          <div class="switch">
            <span>Aktiviert:</span>
            <nz-switch [nzDisabled]="importerAccount.enabled || !isAdmin()"></nz-switch>
          </div>
          <nz-divider></nz-divider>
          <div>
            <h3>Lieferant System ID</h3>
            {{ importerAccount.supplier_system_partner_id }}
          </div>
          <div>
            <h3>ID</h3>
            {{ importerAccount.id }}
          </div>
          <nz-divider *ngIf="isAdmin()"></nz-divider>
          <div *ngIf="isAdmin()">
            <button
              nz-button
              nzType="default"
              (click)="
                deleteImporterAccount(
                  tplDeleteAccount,
                  tplDeleteAccountFooter,
                  importerAccount
                )
              "
            >
              <span nz-icon nzType="delete" nzTheme="outline"></span>
              Importer löschen
            </button>
          </div>
        </nz-card>
        <ng-template #verificationTemplate>
          <ng-container
            [ngSwitch]="importerAccount.id | verificationStatus | async"
          >
            <span
              *ngSwitchCase="'unverified'"
              nz-tooltip
              [nzTooltipTitle]="'Verification in progress'"
              nz-typography
              nzType="secondary"
            >
              <span nz-icon nzType="info-circle" nzTheme="outline"></span>
            </span>
            <span
              *ngSwitchCase="'failed'"
              nz-tooltip
              [nzTooltipTitle]="'Verification failed'"
              nz-typography
              nzType="danger"
            >
              <span nz-icon nzType="warning" nzTheme="outline"></span>
            </span>
            <span
              *ngSwitchCase="'verified'"
              nz-tooltip
              [nzTooltipTitle]="'Verification successful'"
              nz-typography
              nzType="success"
            >
              <span nz-icon nzType="check-circle" nzTheme="outline"></span>
            </span>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #tplContent let-ref="modalRef">
  <form nz-form #credentialForm="ngForm" nzLabelAlign="left">
    <nz-form-item>
      <nz-form-label nzRequired [nzSm]="24" [nzXs]="24"
        >Kunden-Lieferanten-System</nz-form-label
      >
      <nz-form-control
        [nzSm]="24"
        [nzXs]="24"
        nzHasFeedback
        nzErrorTip="Lieferantensystem ist erforderlich"
        nzValidateStatus="{{ supplierSystem == null ? 'error' : 'succes' }}"
      >
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Wählen Sie ein Lieferantensystem"
          [(ngModel)]="supplierSystem"
          name="supplierSystemAuthId"
          (ngModelChange)="selectSupplierSystem($event)"
        >
          <ng-container *ngFor="let supplierSystem of availableSystems">
            <nz-option
              *ngIf="!isAvailableSystemsLoading"
              [nzLabel]="supplierSystem.name"
              [nzValue]="supplierSystem"
            >
            </nz-option>
          </ng-container>
          <nz-option
            *ngFor="let supplierSystem of availableSystems"
            [nzLabel]="supplierSystem.name"
            [nzValue]="supplierSystem"
          >
          </nz-option>
          <nz-option *ngIf="isAvailableSystemsLoading" nzDisabled nzCustomContent>
            <span nz-icon nzType="loading" class="loading-icon"></span>
            Daten werden geladen...
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <div *ngIf="supplierSystem">
      <ng-container *ngIf="existingCredential">
        <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        <span>
          Vorhandene Anmeldedaten werden zur Erstellung der
          Importer-Konfiguration verwendet
        </span>
      </ng-container>
      <ng-container *ngIf="existingCredential == undefined">
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired
            >Benutzername</nz-form-label
          >
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback>
            <input
              [(ngModel)]="(importerAccount?.secrets)!.username"
              nz-input
              name="username"
              placeholder="Benutzername für das Lieferantensystem"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired
            >Passwort</nz-form-label
          >
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback>
            <input
              [(ngModel)]="(importerAccount?.secrets)!.password"
              nz-input
              name="password"
              placeholder="Passwort"
            />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="supplierSystem.supplier_system_auth_id == 'grp'">
          <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired
            >OTP Secret</nz-form-label
          >
          <nz-form-control [nzSm]="24" [nzXs]="24" nzHasFeedback>
            <input
              [(ngModel)]="(importerAccount?.secrets)!.otp_secret_key"
              nz-input
              name="otp"
              placeholder="OTP Geheimnis"
            />
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </div>
  </form>
  <div *ngIf="error" nz-typography nzType="danger">
    {{ error.error_reason }}
  </div>
</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <button nz-button (click)="closeModal(ref)">Abbrechen</button>
  <button
    nz-button
    (click)="newImporterAccount(ref)"
    nzDanger
    nzType="primary"
    [nzLoading]="accountCRUD"
    [disabled]="isCreateDisabled()"
  >
    <span nz-icon nzType="plus"></span>
    Einen Importer hinzufügen
  </button>
</ng-template>

<ng-template #tplDeleteAccount let-ref="modalRef">
  <p>
    Dadurch wird das Konto des Importeurs <b>permanent</b> aus dem System
    entfernt. Dies kann nicht rückgängig gemacht werden.
  </p>
  <p>
    Geben Sie "Löschen" und den Kontonamen in das Feld unten ein, um
    fortzufahren:
  </p>
  <form nz-form>
    <nz-form-item>
      <nz-form-control nzHasFeedback nzErrorTip="Feature name is required">
        <input
          class="form-item-bordered"
          nz-input
          [(ngModel)]="deleteTextValue"
          name="feature_delete_text"
          required="true"
          [placeholder]="
            'Löschen ' + ref.getConfig().nzData.supplier_system_partner_id
          "
        />
      </nz-form-control>
    </nz-form-item>
  </form>
  <div *ngIf="error" nz-typography nzType="danger">
    {{ error.error_reason }}
  </div>
</ng-template>
<ng-template #tplDeleteAccountFooter let-ref="modalRef">
  <button nz-button (click)="ref.close()">Abbrechen</button>
  <button
    nz-button
    (click)="delete(ref)"
    nzDanger
    nzType="primary"
    [nzLoading]="accountCRUD"
    [disabled]="
      deleteTextValue !=
      'Löschen ' + ref.getConfig().nzData.supplier_system_partner_id
    "
  >
    Löschen
  </button>
</ng-template>
  </div>
</div>
