import {Component, OnDestroy, OnInit, inject, TemplateRef} from '@angular/core';
import { NzDrawerPlacement } from "ng-zorro-antd/drawer";
import { Subscription } from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";
import {ContextHolder, FronteggAppService, FronteggAuthService} from '@frontegg/angular';
import { UserInfoService } from 'src/app/services/user-info.service';
import {VehicleService} from "../../services/vehicle.service";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'tt-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  subsctiption = new Subscription()
  trackingService = inject(TrackingService)
  user: any | undefined
  tenantState: any | undefined
  visible = false;
  placement: NzDrawerPlacement = 'right';
  fronteggAuthService = inject(FronteggAuthService)
  userInfoService = inject(UserInfoService)
  vehicleService = inject(VehicleService)
  route = inject(ActivatedRoute)
  notification = inject(NzNotificationService)
  router = inject(Router)
  features: any;
  modal = inject(NzModalService);
  selectedBrand = "";
  selectedOrderId = "";
  selectedOrderType = "";
  selectedCustomerName = "";
  selectedTenantId: any
  isLoading: boolean = false
  IsShowSuccessMessage = window.localStorage.getItem('showSuccessMessage');

  constructor(private fronteggAppService: FronteggAppService) {
    this.userInfoService.getUserFeatures.subscribe( res => {
      this.features = res;
    })
    this.userInfoService.getUser.subscribe(res => {
      this.user = res;
    })
    this.fronteggAuthService.tenantsState$.subscribe( res => {
      this.tenantState = res;
    })
  }

  menuItems = [
    { label: 'Start', icon: 'home', route: '/', features: [] },
    { label: 'News', icon: 'newsstand', route: '/news', features: ['feature-news-feed'] },
    { label: 'Suche', icon: 'search', action: 'searchModal', features: ['feature-vehicles'] },
    { label: 'Fahrzeuge', icon: 'directions_car', route: '/vehicles', features: ['feature-vehicles'] },
    { label: 'Expert', icon: 'table_eye', route: '/expert-view', features: ['feature-expert-view', 'feature-vehicles'] },
    { label: 'Neuwagen', icon: 'tv', route: '//neuwagenmonitor', features: ['feature-new-vehicle-monitor'] },
  ];

  getAvailableMenuItems(): any[] {
    return this.menuItems.filter(item =>
      item.features.every(feature => this.hasFeature(feature))
    );
  }

  getMainMenuItems(): any[] {
    const availableItems = this.getAvailableMenuItems();
    return availableItems.length > 5 ? availableItems.slice(0, 4) : availableItems;
  }

  getDropdownMenuItems(): any[] {
    const availableItems = this.getAvailableMenuItems();
    return availableItems.length > 5 ? availableItems.slice(4) : [];
  }

  ngOnInit(): void {
    if(this.IsShowSuccessMessage){
      window.localStorage.removeItem('showSuccessMessage');
      this.notification.success(
        "",
        "Mandant erfolgreich gewechselt",
      )
    }
    if(!this.vehicleService.vehicleBrands.length){
      this.subsctiption.add(
        this.vehicleService.getDistinctValues('vehicle_brand').subscribe(data => {
          this.vehicleService.vehicleBrands = data;
        })
      )
    }
    if(!this.vehicleService.vehicleOrderTypes.length){
      this.subsctiption.add(
        this.vehicleService.getDistinctValues('cardealer_vehicle_order_type').subscribe(data => {
          this.vehicleService.vehicleOrderTypes = data.map((word: string) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
        })
      )
    }
    this.selectedTenantId = this.tenantState.activeTenant.tenantId
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  hasFeature(feature: any){
    if(this.features){
      return this.features?.indexOf(feature) != -1;
    } else return false
  }

  logout(): void {
    localStorage.removeItem("isTenantSelected");
    const baseUrl = ContextHolder.getContext().baseUrl;
    this.trackingService.trackEvent(ACTIONS.logout_clicked, {})
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.href}`;
  }

  showAdminPortal(): void {
    this.fronteggAppService?.showAdminPortal()
  }

  profileClicked(){
    this.trackingService.trackEvent(ACTIONS.header_profile_button_clicked, {})
  }

  isAdmin() {
    return this.user.roles.some((role: any) => role.key == "Admin")
  }

  developerClicked(){
    this.trackingService.trackEvent(ACTIONS.header_developer_button_clicked, {})
  }

  supportClicked(){
    this.trackingService.trackEvent(ACTIONS.header_support_button_clicked, {})
  }

  ngOnDestroy(): void {
      this.subsctiption.unsubscribe()
  }

  openSearchModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>){
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Suche',
      nzContent: tplContent,
      nzFooter: tplFooter,

    });
  }

  openChangeTenantModal(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>){
    const modal: NzModalRef = this.modal.create({
      nzTitle: 'Umgebung wechseln',
      nzContent: tplContent,
      nzFooter: tplFooter,
    });
  }

  switchTenant(ref: any){
    this.fronteggAuthService.switchTenant({ tenantId: this.selectedTenantId })
    this.subsctiption.add(
      this.fronteggAppService.isLoading$.subscribe((isLoading) => {
        this.isLoading = isLoading
        if(!isLoading){
          ref.destroy();
          //should be refactored. All pages should be updated(features, vehicles, importers, developer etc...)
          window.localStorage.setItem('showSuccessMessage', 'true');
          window.location.reload();
        }
      })
    )
  }



  setActiveTenant(tenantId: any) {
    this.selectedTenantId = tenantId;
  }

  setFilterData(ref: any) {
    this.vehicleService.wildcardSearch = [];
    this.vehicleService.filters = [];
    if (this.selectedOrderId) {
      this.vehicleService.filters.push({attribute: 'production_order_id', values: [this.selectedOrderId]})
      this.selectedOrderId = "";
    }
    if (this.selectedBrand) {
      this.vehicleService.filters.push({attribute: 'vehicle_brand', values: [this.selectedBrand]})
      this.selectedBrand = ""
    }
    if (this.selectedCustomerName) {
      this.vehicleService.wildcardSearch.push({attribute: 'cardealer_customer_name', value: this.selectedCustomerName})
      this.selectedCustomerName = ""
    }
    if (this.selectedOrderType) {
      this.vehicleService.filters.push({attribute: 'cardealer_vehicle_order_type', values: [this.selectedOrderType]})
      this.selectedOrderType = "";
    }
    ref.destroy();
  }

  handleModalClose(ref: any){
    this.selectedOrderId = "";
    this.selectedBrand = ""
    this.selectedCustomerName = ""
    this.selectedOrderType = "";
    ref.destroy();
  }
  isHomeRouteActive(): boolean {
    return this.router.isActive('/', true) || this.router.isActive('/home', true);
  }

}
