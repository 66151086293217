<div class="page">
  <div class="page-content">
    <div class="header-container">
      <h3 class="header-title">Kunden & Partner</h3>
      <div class="header-actions-container">
        <!-- <nz-input-group [nzSize]="'large'" [nzSuffix]="suffixIconSearch">
          <input
            type="text"
            nz-input
            placeholder="Suche nach Großkunden"
            [(ngModel)]="searchInputValue"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="log()"
          />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span nz-icon nzType="search"></span>
        </ng-template> -->
        <button
          class="primary-button"
          nz-button
          nzSize="large"
          nzType="primary"
          nzDanger
          (click)="createKeyAccountModal(tplContent, tplFooter)"
        >
          <span nz-icon nzType="plus" nzTheme="outline"></span>
          Kunde oder Partner hinzufügen
        </button>
      </div>
    </div>
    <div class="content">
      <nz-spin [nzSpinning]="isLoading && !keyaccounts"></nz-spin>
      <nz-empty
        *ngIf="!keyaccounts && !isLoading"
        [nzNotFoundContent]="contentTpl"
        [nzNotFoundFooter]="footerTpl"
        class="empty"
      >
        <ng-template #contentTpl>
          <span
            >Es wurden noch keine Ansichten erstellt. Beginnen Sie mit dem
            Hinzufügen der ersten Ansicht.</span
          >
        </ng-template>
        <ng-template #footerTpl>
          <button
            nz-button
            nzSize="large"
            nzType="primary"
            nzDanger=""
            (click)="createKeyAccountModal(tplContent, tplFooter)"
          >
            Kunde oder Partner hinzufügen
          </button>
        </ng-template>
      </nz-empty>
      <nz-table
        *ngIf="keyaccounts"
        #keyAcountsTable
        [nzLoading]="isLoading"
        [nzData]="keyaccounts"
        [nzShowPagination]="false"
        [nzSize]="'small'"
        class="keyaccounts"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Erstellt von</th>
            <th>Erstellungsdatum</th>
            <th *ngIf="isAdmin()"></th>
          </tr>
        </thead>
        <tbody>
          @for (item of keyAcountsTable.data; track $index) {
          <tr>
            <td>
              {{ item.name }}
              <nz-tag
                class="tag"
                [nzColor]="item.is_active ? 'success' : 'warning'"
                >{{ item.is_active ? "Aktiv" : "Inaktiv" }}</nz-tag
              >
            </td>
            <td>{{ item.created_by }}</td>
            <td>{{ item.created_at }}</td>
            <td (click)="$event.stopPropagation()" *ngIf="isAdmin()">
              <button nz-button nzType="text" (click)="editKeyAccount(item.id!)">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </button>
              <button
                nz-button
                nzType="text"
                (click)="deleteKeyAccountModal(tplDeleteModal, tplDeleteModalFooter, item)"
              >
                <span nz-icon nzType="delete" nzTheme="outline"></span>
              </button>
            </td>
          </tr>
          }
        </tbody>
      </nz-table>
    </div>

    <ng-template #tplContent let-ref="modalRef">
      <form nz-form>
        <nz-form-label nzRequired nzNoColon>Kunden & Partner</nz-form-label>
        <nz-form-item>
          <nz-form-control nzHasFeedback nzErrorTip="Titel ist erforderlich">
            <input
              nz-input
              name="name"
              required="true"
              [(ngModel)]="ref.getConfig().nzData.name"
            />
          </nz-form-control>
        </nz-form-item>
        <div class="modalCreate">
          <div>
            <nz-form-label nzRequired>Name</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="Benutzername ist erforderlich">
                <input
                  nz-input
                  name="user_name"
                  required="true"
                  [(ngModel)]="ref.getConfig().nzData.user_name"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div>
            <nz-form-label nzRequired>Email</nz-form-label>
            <nz-form-item>
              <nz-form-control nzErrorTip="EMail-Adresse ist erforderlich">
                <input
                  nz-input
                  email
                  name="email"
                  required="true"
                  [(ngModel)]="ref.getConfig().nzData.email"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
      <ng-container [ngTemplateOutlet]="errorMsg ? error : info">
      </ng-container>
      <ng-template #info>
        <nz-alert
          class="alert-message"
          nzType="error"
          nzMessage="Für jede Ansicht wird eine monatliche Gebühr von 15€ erhoben."
          nzShowIcon
          [nzIcon]="customIconTemplate"
          >
        </nz-alert>
        <ng-template #customIconTemplate>
          <span nz-icon nzType="info-circle" nzTheme="fill"></span>
        </ng-template>
      </ng-template>
      <ng-template #error>
        <nz-alert
          nzType="error"
          nzMessage="Es ist ein Fehler aufgetreten"
          [nzDescription]="errorMsg"
          nzShowIcon
        ></nz-alert>
      </ng-template>


    </ng-template>
    <ng-template #tplFooter let-ref="modalRef">
      <button nz-button (click)="ref.close()">Abbrechen</button>
      <button
        nzType="primary"
        nzDanger
        [nzLoading]="isCRUD"
        [disabled]="isDisabled(ref)"
        nz-button
        (click)="createKeyAccount(ref)"
      >
        Speichern
      </button>
    </ng-template>
    <ng-template #tplDeleteModal>
      <p>
        Wenn Sie eine Kunden & Partner löschen, werden alle Einstellungen entfernt und
        der Benutzerzugriff wird gesperrt. Die monatliche Gebühr für diese
        Ansicht wird für den nächsten Abrechnungszyklus entfernt.
      </p>
      <p nz-typography nzType="danger">
        <strong>Dies kann nicht rückgängig gemacht werden.</strong>
      </p>
    </ng-template>

    <ng-template #tplDeleteModalFooter let-ref="modalRef">
      <button nz-button (click)="log()" class="form-button">
        Kunden & Partner beibehalten
      </button>
      <button
        nz-button
        [nzLoading]="isCRUD"
        (click)="deleteKeyAccount(ref)"
        class="form-button"
        nzType="primary"
        nzDanger
      >
        Kunden & Partner löschen
      </button>
    </ng-template>
  </div>
</div>
