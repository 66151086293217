import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NewsService } from './news.service';
import { Subscription } from 'rxjs';
import { NewsSource, RssNews, } from 'src/app/models/news';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";

@Component({
  selector: 'tt-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {

  private subscription = new Subscription()
  newsService = inject(NewsService);
  trackingService = inject(TrackingService);
  route = inject(ActivatedRoute)
  rssNews: RssNews[] = [];
  cachedNews: RssNews[] = [];
  loadingMore = true;
  isLoadMoreBtnVisible = true;
  hasMoreNews = true;
  page = 1;
  sourceMap: NewsSource = new NewsSource
  newsPerPage = 12
  isNewsPage = true;

  routerSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.isNewsPage = this.route.snapshot.routeConfig?.path == "news"
    this.subscription.add(
      this.newsService.getNews(this.page, this.newsPerPage).subscribe(
        news => {
          this.rssNews = news.data.slice(0, this.newsPerPage/2);
          this.cachedNews = news.data.slice(this.newsPerPage/2 + 1);
          this.sourceMap = news.image_logo_mapping
          this.loadingMore = false;
          if(this.isNewsPage) {
            this.onLoadMore();
          }
        }
      )
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  formatDate(date: string) {
    const currentDate = new Date(date);
    const yyyy = currentDate.getFullYear();
    let mm:any = currentDate.getMonth() + 1; // Months start at 0!
    let dd:any = currentDate.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy
  }

  loadNews(perPage?: number){
    this.page += 1;
    this.newsService.getNews(this.page, perPage).subscribe(
      news => {
        this.rssNews = this.rssNews.concat(news.data)
        this.loadingMore = false;
      }
    )
  }

  onLoadMore() {
    this.rssNews = this.rssNews.concat(this.cachedNews)
    this.isLoadMoreBtnVisible = false
    this.loadingMore = true
    this.loadNews(this.newsPerPage)
    this.trackingService.trackEvent(ACTIONS.news_load_more_button_clicked, {})
  }


  onScroll(e: any) {
    if (!this.isLoadMoreBtnVisible) {
      this.loadingMore = true
      this.loadNews(this.newsPerPage);
    }
  }

  getId(feed: any) { return feed['yt:videoId'] }
}
