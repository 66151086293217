import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';

@Pipe({
  name: 'yomaDateFormat',
  standalone: true
})
export class YomaDateFormatPipe implements PipeTransform {
  
  transform(value: string | number | Date, dateFormat: string): string {
    setDefaultOptions({ locale: de })
    if (!value) return ''
    if (typeof value === 'string') {
      return format(parseISO(value), dateFormat);
    } else if (typeof value === 'number') {
      return format(value * 1000, dateFormat);
    }
    else {
      return format(value, dateFormat);
    }
  }
}
