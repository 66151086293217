import {Component, inject, OnInit} from '@angular/core';
import {VehicleBrandsService} from "../../services/vehicle-brands.service";
import {VehicleBrand, VehicleBrands} from "../../models/VehicleBrand";
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";
import {UserInfoService} from "../../services/user-info.service";
import {Router} from "@angular/router";

@Component({
  selector: 'tt-fleet-prediction',
  templateUrl: './fleet-prediction.component.html',
  styleUrls: ['./fleet-prediction.component.scss']
})
export class FleetPredictionComponent implements OnInit {
  trackingService = inject(TrackingService);
  userInfoService = inject(UserInfoService);
  isLoading = true;
  router = inject(Router);

  constructor(private carBrandsService: VehicleBrandsService) {
  }

  public brandsData?: VehicleBrand[] = [];
  public filteredBrandsData?: VehicleBrand[];

  ngOnInit() {
    this.userInfoService.getUserFeatures.subscribe(res => {
      if(res.indexOf('feature-new-vehicle-monitor') < 0){
        this.router.navigate(['/landing/feature-new-vehicle-monitor']);
      }
    })
    this.carBrandsService.getVehicleBrands().subscribe((data: VehicleBrands) => {
      this.brandsData = data?.results
      this.filteredBrandsData = this.brandsData;
      this.isLoading = false
    })
    this.trackingService.trackEvent(ACTIONS.neuwagenmonitor_page_loaded, {})
  }

  setFilteredBrandData = (searchValue: string) => {
    this.filteredBrandsData = this.brandsData?.filter((item) => item.brand?.toLowerCase().includes(searchValue))
  }

  selectBrand(brand: any){
    this.trackingService.trackEvent(ACTIONS.neuwagenmonitor_brand_selected, {value: brand})
  }

}
